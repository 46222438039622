import React from "react";
export const PanelStatsHeadTable = () => {
  return (
    <thead className="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        <th scope="col" className="px-2 py-3">
          user_id
        </th>
        <th scope="col" className="px-2 py-3">
          user_name
        </th>
        <th scope="col" className="px-6 py-3">
          user_firstname
        </th>
        <th scope="col" className="px-4 py-3">
          user_lastname
        </th>
        <th scope="col" className="px-4 py-3">
          user_link
        </th>
        <th scope="col" className="px-4 py-3">
          command
        </th>
        <th scope="col" className="px-4 py-3">
          timestamp
        </th>
      </tr>
    </thead>
  );
};

export const PanelStatsTr = ({ data }) => {
  const {
    user_id,
    user_name,
    user_firstname,
    user_lastname,
    user_link,
    command,
    timestamp,
  } = data;
  return (
    <tr className={["border-b dark:border-gray-700"].join(" ")}>
      <td className="px-2 py-3 max-w-[5rem] truncate">{user_id}</td>
      <td className="px-2 py-3 max-w-[5rem] truncate">{user_name}</td>
      <td className="px-2 py-3 max-w-[5rem] truncate">{user_firstname}</td>
      <td className="px-2 py-3 max-w-[5rem] truncate">{user_lastname}</td>
      <td className="px-2 py-3 max-w-[10rem] truncate">
        <a href={user_link} target="_blank" rel="noreferrer noopener">
          {user_link}
        </a>
      </td>
      <td className="px-2 py-3 min-w-[15rem] max-w-[20rem]">{command}</td>
      <td className="px-2 py-3 max-w-[10rem] truncate">{timestamp}</td>
    </tr>
  );
};
