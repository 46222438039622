import React, { useState, useEffect } from "react";
import {
  Button,
  TextInput,
  Box,
  Alert,
  Fieldset,
  Title,
  LoadingOverlay,
  TagsInput,
} from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { v4 as uuidv4 } from "uuid";
import { useDisclosure } from "@mantine/hooks";
import { errorNotification, successNotification } from "../../../libs/util";

// libs
import { updateCamera, createCamera, getCamerasTags } from "../../../libs/api";
import ImageDropzone from "./ImageDropzone";

const PanelCamerasForm = ({ token, camera, fetchData, closeAction, type }) => {
  const [visible, { toggle, close }] = useDisclosure(false);
  const [predictionTags, setPredictionTags] = useState([]);

  useEffect(() => {
    fetchCamerasTags();
  }, []);

  const fetchCamerasTags = async () => {
    try {
      const response = await getCamerasTags(token);
      const tags = response?.data;
      if (tags && tags?.length > 0) {
        setPredictionTags(tags.map((tag, _) => tag.name));
      }
    } catch (e) {
      console.error("Failed to get tags");
    }
  };

  const sendEdit = async (cameraId, data) => {
    toggle();
    try {
      const updatedCamera = await updateCamera(token, cameraId, data);
      if (!updatedCamera || updatedCamera?.detail) {
        errorNotification("Ошибка", "Произошла ошибка при обновлении камеры!");
        close();
        return;
      }
      await fetchData();
    } catch (e) {
      errorNotification("Ошибка", "Произошла ошибка при обновлении камеры!");
      console.error("Failed to update camera");
      close();
      return;
    }
    close();
    closeAction();
    successNotification("Успешно", "Камера обновлена!");
  };

  const sendAdd = async (data) => {
    toggle();
    try {
      const newCamera = await createCamera(token, data);
      if (!newCamera || newCamera?.detail) {
        errorNotification("Ошибка", "Произошла ошибка при добавлении камеры!");
        close();
        return;
      }
      await fetchData();
    } catch (e) {
      errorNotification("Ошибка", "Произошла ошибка при добавлении камеры!");
      console.error("Failed to send camera");
      close();
      return;
    }
    close();
    closeAction();
    successNotification("Успешно", "Камера добавлена!");
  };

  const inputWrapperOrder = ["label", "error", "input", "description"];

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      idd: Number(uuidv4().replace(/\D/g, "").substring(0, 8)),
      title: camera?.title || "",
      link: camera?.link || "",
      audio: camera?.audio || "",
      site: camera?.site || "",
      timecode: camera?.timecode || "",
      inner_data: {
        audio_input: camera?.inner_data?.audio_input || "",
        camera_model: camera?.inner_data?.camera_model || "",
        timecode_input: camera?.inner_data?.timecode_input || "",
      },
      tags: (camera?.tags && camera?.tags.map((tag) => tag.name)) || [],
    },

    validate: {
      title: (value) =>
        value.length < 2 ? "Заголовок должен содержать не менее 2 букв." : null,
      site: (value) =>
        value.length < 2 ? "Сайт должен содержать не менее 2 букв." : null,
    },
  });

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        type === "edit" ? sendEdit(camera.id, values) : sendAdd(values);
      })}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      {type === "add" && (
        <Alert variant="light" color="blue" icon={<IconInfoCircle />} mb="md">
          Изображения и файлы можно будет добавить после публикации
        </Alert>
      )}
      {type === "edit" && (
        <Fieldset
          legend={<Title order={4}>Файлы:</Title>}
          variant="filled"
          p="sm"
        >
          <ImageDropzone fetchData={fetchData} camera={camera} token={token} />
        </Fieldset>
      )}
      <Fieldset
        legend={<Title order={4}>Данные для главной страницы:</Title>}
        variant="filled"
        p="sm"
        mt="md"
      >
        {type === "add" && (
          <TextInput
            label="idd"
            placeholder="idd"
            description="Имя будущей папки, где будут файлы. Сделаем автогенерацию имен, зачем самому придумывать"
            rightSectionPointerEvents="all"
            mb="md"
            disabled={true}
            inputWrapperOrder={inputWrapperOrder}
            key={form.key("idd")}
            {...form.getInputProps("idd")}
          />
        )}
        <TextInput
          data-autofocus
          withAsterisk
          label="Заголовок"
          placeholder="Заголовок"
          description="Используется для вывода в админке. А также в поиске у бота!"
          rightSectionPointerEvents="all"
          mt={type === "add" && "md"}
          mb="md"
          inputWrapperOrder={inputWrapperOrder}
          key={form.key("title")}
          {...form.getInputProps("title")}
        />
        <TagsInput
          label="Теги"
          maxTags={20}
          placeholder="Добавьте теги"
          description="Теги помогут лучше искать камеру"
          rightSectionPointerEvents="all"
          mt="md"
          mb="md"
          inputWrapperOrder={inputWrapperOrder}
          key={form.key("tags")}
          {...form.getInputProps("tags")}
          data={predictionTags}
        />
        <TextInput
          label="Ссылка на источник"
          placeholder="Ссылка на источник"
          description="Ссылка на сайт, откуда была взята информация. Не используется нигде"
          rightSectionPointerEvents="all"
          mt="md"
          mb="md"
          inputWrapperOrder={inputWrapperOrder}
          key={form.key("link")}
          {...form.getInputProps("link")}
        />
        <TextInput
          label="audio"
          placeholder="audio"
          description="Не обязательно. (бралось с главной страницы в граббере, не знаю зачем)"
          rightSectionPointerEvents="all"
          mt="md"
          mb="md"
          inputWrapperOrder={inputWrapperOrder}
          key={form.key("audio")}
          {...form.getInputProps("audio")}
        />
        <TextInput
          withAsterisk
          label="Название сайта"
          placeholder="Название сайта"
          description="С какого сайта взято (название сайта, будет использоваться в папке /tmp для картинок). Имеет смысл использовать одно название, чтобы не плодить бесполезные папки"
          rightSectionPointerEvents="all"
          mt="md"
          mb="md"
          inputWrapperOrder={inputWrapperOrder}
          key={form.key("site")}
          {...form.getInputProps("site")}
        />
        <TextInput
          label="timecode"
          placeholder="timecode"
          description="Не обязательно. (Тажа история, что и с audio)"
          rightSectionPointerEvents="all"
          mt="md"
          mb="md"
          inputWrapperOrder={inputWrapperOrder}
          key={form.key("timecode")}
          {...form.getInputProps("timecode")}
        />
      </Fieldset>
      <Fieldset
        legend={<Title order={4}>Данные для вывода в карточке камеры:</Title>}
        mt="md"
        variant="filled"
        p="sm"
      >
        <TextInput
          label="Аудиовход"
          placeholder="Аудиовход"
          rightSectionPointerEvents="all"
          mb="md"
          inputWrapperOrder={inputWrapperOrder}
          key={form.key("inner_data.audio_input")}
          {...form.getInputProps("inner_data.audio_input")}
        />
        <TextInput
          label="Модель камеры"
          placeholder="Модель камеры"
          rightSectionPointerEvents="all"
          mt="md"
          mb="md"
          inputWrapperOrder={inputWrapperOrder}
          key={form.key("inner_data.camera_model")}
          {...form.getInputProps("inner_data.camera_model")}
        />
        <TextInput
          label="ТС разъем"
          placeholder="ТС разъем"
          rightSectionPointerEvents="all"
          mt="md"
          mb="md"
          inputWrapperOrder={inputWrapperOrder}
          key={form.key("inner_data.timecode_input")}
          {...form.getInputProps("inner_data.timecode_input")}
        />
      </Fieldset>
      <Box className="flex mt-2 justify-center">
        <Button type="submit" size="md" fullWidth>
          {type === "edit" ? "Изменить" : "Добавить"}
        </Button>
      </Box>
    </form>
  );
};

export default PanelCamerasForm;
