import React from "react";
import LazyLoad from "react-lazy-load";
import { NavLink, Badge, Pill } from "@mantine/core";

// libs
import { makeImageLink } from "../../../libs/util";
import DeleteIcon from "../Layout/DeleteIcon";
import EditIcon from "../Layout/EditIcon";
import HoverPopover from "./HoverPopover";
import { TELERGAM_URL } from "../../../libs/api";

export const PanelCamerasTr = ({ camera, token, submitEdit, submitDelete }) => {
  const { site, id, title, inner_data, tags } = camera;
  const { images, files, audio_input, camera_model, timecode_input } =
    inner_data;
  const command = `camera_${id}`;
  const link = `${TELERGAM_URL}?start=-${command}`;

  return (
    <tr className={["border-b dark:border-gray-700"].join(" ")}>
      {/* <td className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white max-w-[10rem] truncate">
        {id}
      </td> */}
      <td className="px-2 py-3 max-w-[10rem] truncate">
        <NavLink
          href={link}
          label={command}
          target="_blank"
          rel="noreferrer noopener"
          leftSection={
            <Badge size="md" color="blue">
              {id}
            </Badge>
          }
        />
      </td>
      <td className="px-2 py-3 max-w-[8rem] truncate">
        <HoverPopover type="full" token={token} camera={camera}>
          {title}
        </HoverPopover>
      </td>
      <td className="px-2 py-3 max-w-[15rem] truncate">
        {tags?.length > 0 ? (
          <div className="flex gap-2 flex-wrap">
            {tags.map(({ name }, i) => (
              <Pill size="xs" key={i}>
                {name}
              </Pill>
            ))}
          </div>
        ) : (
          "-"
        )}
      </td>
      <td className="px-6 py-3 min-w-[17rem] max-w-[17rem] truncate flex flex-wrap gap-2">
        {images.map((image, i) => {
          const imageLink = makeImageLink(site, image.dest);
          const imageThumbLink = makeImageLink(site, image.dest, "images");
          return (
            <a href={imageLink} key={i} target="_blank" rel="noreferrer">
              <HoverPopover type="image" token={token} camera={imageLink}>
                <LazyLoad height={30}>
                  <div
                    className="!w-[30px] !h-[30px] !bg-cover !bg-no-repeat !bg-center"
                    style={{
                      backgroundImage: `url("${imageThumbLink}")`,
                    }}
                  />
                </LazyLoad>
              </HoverPopover>
            </a>
          );
        })}
        {files.map((file, i) => {
          const fileType = file.file_type;
          const fileLink = makeImageLink(site, file.dest);
          switch (fileType) {
            case "pdf":
              return (
                <a
                  href={fileLink}
                  key={i}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <LazyLoad height={30}>
                    <div
                      className="!w-[30px] !h-[30px] !bg-cover !bg-no-repeat !bg-center"
                      style={{
                        backgroundImage: `url("/pdf.png")`,
                      }}
                    />
                  </LazyLoad>
                </a>
              );
            default:
              return <></>;
          }
        })}
      </td>
      <td className="px-4 py-3 max-w-[12rem] truncate">{camera_model}</td>
      <td className="px-4 py-3 max-w-[12rem] truncate">{audio_input}</td>
      <td className="px-4 py-3 max-w-[12rem] truncate">{timecode_input}</td>
      <td className="px-4 py-3 max-w-[7rem] truncate">
        <button
          type="button"
          data-modal-target="deleteModal"
          data-modal-toggle="deleteModal"
          className="inline-flex items-center text-sm font-medium hover:bg-gray-100 dark:hover:bg-gray-700 p-1.5 dark:hover-bg-gray-800 text-center hover:text-gray-800 rounded-lg focus:outline-none dark:text-gray-400 text-gray-600 dark:hover:text-gray-500"
          title="Редактировать"
          onClick={() => submitEdit(camera)}
        >
          <EditIcon />
        </button>
        <button
          type="button"
          data-modal-target="deleteModal"
          data-modal-toggle="deleteModal"
          className="inline-flex items-center text-sm font-medium hover:bg-gray-100 dark:hover:bg-gray-700 p-1.5 dark:hover-bg-gray-800 text-center hover:text-gray-800 rounded-lg focus:outline-none dark:text-gray-400 text-red-500 dark:hover:text-red-400"
          title="Удалить"
          onClick={() => submitDelete(camera.id, camera.title)}
        >
          <DeleteIcon />
        </button>
      </td>
    </tr>
  );
};

export const PanelCamerasHeadTable = () => (
  <thead className="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
    <tr>
      {/* <th scope="col" className="px-4 py-4">
        id
      </th> */}
      <th scope="col" className="px-2 py-3">
        Ссылка
      </th>
      <th scope="col" className="px-2 py-3">
        Заголовок
      </th>
      <th scope="col" className="px-2 py-3">
        Теги
      </th>
      <th scope="col" className="px-6 py-3">
        Файлы
      </th>
      <th scope="col" className="px-4 py-3">
        Модель камеры
      </th>
      <th scope="col" className="px-4 py-3">
        Аудиовход
      </th>
      <th scope="col" className="px-4 py-3">
        ТС разъем
      </th>
      <th scope="col" className="px-4 py-3">
        <span className="sr-only">Actions</span>
      </th>
    </tr>
  </thead>
);
