import React from "react";

const Hello = (props) => {
  const { type, description, author, url, version } = props;

  if (navigator.userAgent.toLowerCase().indexOf("chrome") > -1) {
    const args = [
      `\n %c by ${author} %c ${url} %c %c ♥ ♥ ♥ ${version} \n\n`,
      "border: 1px solid #000;color: #fff; background: #07a2cf; padding:5px 0;",
      "color: #fff; background: #1c1c1c; padding:5px 0;border: 1px solid #000;",
      "background: #fff; padding:5px 0;",
      "color: #b0976d; background: #fff; padding:5px 0;",
    ];
    window.console.log.apply(console, args);
    if (type === "description" && description) {
      args.push(`Description: ${description}`);
    }
  } else if (window.console) {
    window.console.log(`by ${author} - ${url}`);
  }
  return "";
};

export default Hello;
