import React from "react";
import { Loader } from "@mantine/core";

export const Loading = () => {
  return (
    <div className="fixed top-0 left-0 flex justify-center items-center h-full w-full">
      <Loader color="blue" />
    </div>
  );
};

export const LoadingPanel = ({ children, no_p }) => {
  return (
    <div
      className={["flex justify-center items-center", no_p ? "" : "p-5"].join(
        " "
      )}
    >
      {children}
    </div>
  );
};
