import React, { createContext, useContext, useEffect, useState } from "react";
import { Loading } from "../Panel/Layout/Loading";
import { verifyToken } from "../../libs/api";

// Создание контекста
const InitContext = createContext();

// Провайдер контекста
export const InitProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const savedToken = localStorage.getItem("token");
    const savedUsername = localStorage.getItem("username");

    if (savedToken) {
      verifyToken(savedToken)
        .then(() => {
          setToken(savedToken);
          setUsername(savedUsername);
        })
        .catch((error) => {
          console.error("Invalid token:", error);
          localStorage.removeItem("token");
          localStorage.removeItem("username");
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  const handleLogin = (token, username) => {
    setToken(token);
    setUsername(username);
    localStorage.setItem("token", token);
    localStorage.setItem("username", username);
  };

  const handleLogout = () => {
    setToken(null);
    setUsername("");
    localStorage.removeItem("token");
    localStorage.removeItem("username");
  };

  return (
    <InitContext.Provider
      value={{ token, username, handleLogin, handleLogout }}
    >
      {loading ? <Loading /> : children}
    </InitContext.Provider>
  );
};

// Хук для использования контекста
export const useInitProvider = () => {
  return useContext(InitContext);
};
