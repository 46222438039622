import { API_HOST } from "./api";
import { notifications } from "@mantine/notifications";
import { IconX, IconCheck } from "@tabler/icons-react";
import { rem } from "@mantine/core";

export const makeImageLink = (site, str, type = "tmp", size = 200) => {
  if (type === "images") {
    return `${API_HOST}/images/${size}/${site}${str}`;
  }
  return `${API_HOST}/${type}/${site}${str}`;
};

export const errorNotification = (title, message) => {
  notifications.show({
    id: "error-message",
    withCloseButton: true,
    autoClose: 5000,
    title: title,
    message: message,
    color: "red",
    icon: <IconX />,
    loading: false,
  });
};

export const successNotification = (title, message) => {
  notifications.show({
    id: "success-message",
    withCloseButton: true,
    autoClose: 5000,
    title: title,
    message: message,
    color: "green",
    icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
    loading: false,
  });
};
