import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextInput,
  PasswordInput,
  Button,
  Group,
  Fieldset,
  LoadingOverlay,
  Container,
  Center,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Helmet } from "react-helmet-async";
import { useDisclosure } from "@mantine/hooks";
import HCaptcha from "@hcaptcha/react-hcaptcha"; // импорт HCaptcha

// libs
import { login } from "../../../libs/api";
import Main from "../Layout/Main";
import { errorNotification } from "../../../libs/util";
import { useInitProvider } from "../../context/InitContext";

const LoginForm = () => {
  const { handleLogin: onLogin } = useInitProvider();
  const [error, setError] = useState("");
  const [visible, { toggle, close }] = useDisclosure(false);
  const [captchaToken, setCaptchaToken] = useState("");

  const captchaRef = useRef(null);

  const navigate = useNavigate();

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      username: "",
      password: "",
    },
  });

  const handleSubmit = async ({ username, password }) => {
    if (!captchaToken) {
      // Проверка, был ли пройден hCaptcha
      setError("Пожалуйста, пройдите проверку hCaptcha.");
      return;
    }
    try {
      toggle();
      const { access_token } = await login(username, password, captchaToken); // передаем captchaToken на сервер
      onLogin(access_token, username);
      setError("");
      navigate("/panel");
    } catch (e) {
      setError("Ошибка авторизации");
      errorNotification("Ошибка авторации", "Произошла ошибка при авторизации");
    }
    close();
  };

  const onVerifyCaptcha = (token) => {
    // обработчик успешной проверки hCaptcha
    setCaptchaToken(token);
    setError(""); // очищаем ошибку
  };

  return (
    <>
      <Helmet>
        <title>Авторизация</title>
      </Helmet>
      <Container size="xs" style={{ height: "100vh" }}>
        <Center style={{ height: "100%" }}>
          <Main className="mt-5 mb-5">
            <div className="mx-auto min-w-72">
              <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                <div className="overflow-x-auto p-2">
                  <form
                    onSubmit={form.onSubmit((values) => handleSubmit(values))}
                  >
                    <Fieldset legend="Авторизация" variant="filled" p="sm">
                      <TextInput
                        label="Логин"
                        placeholder="Логин"
                        key={form.key("username")}
                        {...form.getInputProps("username")}
                      />
                      <LoadingOverlay
                        visible={visible}
                        zIndex={1000}
                        overlayProps={{ radius: "sm", blur: 2 }}
                      />
                      <PasswordInput
                        mt="md"
                        label="Пароль"
                        placeholder="Пароль"
                        key={form.key("password")}
                        {...form.getInputProps("password")}
                      />

                      <Group justify="center" className="flex-col" mt="md">
                        <HCaptcha
                          sitekey={process.env.REACT_APP_HCAPTCHA_SITEKEY}
                          onVerify={onVerifyCaptcha}
                          ref={captchaRef}
                        />
                        {error && <div>{error}</div>}
                        <Button type="submit" fullWidth>
                          Войти
                        </Button>
                      </Group>
                    </Fieldset>
                  </form>
                </div>
              </div>
            </div>
          </Main>
        </Center>
      </Container>
    </>
  );
};

export default LoginForm;
