import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Button, Text, Image } from "@mantine/core";
import { modals } from "@mantine/modals";

// libs
import {
  errorNotification,
  successNotification,
  makeImageLink,
} from "../../../libs/util";
import { uploadFiles, getFiles, deleteFile } from "../../../libs/api";

// styles
import "dropzone/dist/dropzone.css";

const ImageDropzone = ({ fetchData, camera, token }) => {
  const [data, setData] = useState([]);
  const [previews, setPreviews] = useState({});

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const files = await getFiles(camera.id, token);
        setData(files || []);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, [camera.id, token]);

  const openDeleteModal = (fileDest, fileType) =>
    modals.openConfirmModal({
      title: "Удаление файла",
      centered: true,
      children: (
        <Text size="sm">
          Уверены, что хотите удалить "{fileDest}" файл? Действие не возвратно
        </Text>
      ),
      labels: { confirm: "Удалить", cancel: "Отмена" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => handleDelete(fileDest, fileType),
    });

  const submitDelete = (fileDest, fileType) => {
    openDeleteModal(fileDest, fileType);
  };

  const onDrop = async (acceptedFiles) => {
    try {
      const formData = new FormData();
      const previewURLs = {};
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (file.type === "application/pdf") {
            // Для PDF файлов, просто сохраняем имя файла как превью
            previewURLs[file.name] = "/pdf.png"; // Или путь к иконке PDF
          } else {
            // Для изображений
            previewURLs[file.name] = reader.result;
          }
          setPreviews((prev) => ({ ...prev, ...previewURLs }));
        };
        formData.append("files", file);
        if (file.type.startsWith("image/")) {
          reader.readAsDataURL(file);
        } else {
          reader.readAsArrayBuffer(file);
        }
      });

      await uploadFiles(camera.id, camera.site, token, camera.idd, formData);

      const files = await getFiles(camera.id, token);
      setData(files || {});
      setPreviews({});
      successNotification("Успешно", "Файлы загружены!");
      await fetchData();
    } catch (error) {
      console.error("Error uploading files:", error);
      errorNotification("Ошибка", "Произошла ошибка при загрузке файлов!");
    }
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    accept: { "image/*": [], "application/pdf": [] },
    multiple: true,
  });

  const handleDelete = async (filePath, fileType) => {
    try {
      await deleteFile(camera.id, token, filePath, fileType, camera.site);
      const files = await getFiles(camera.id, token);
      setData(files || {});
      setPreviews({});
    } catch (error) {
      console.error("Error deleting image:", error);
      setPreviews({});
      setData({});
    }
    await fetchData();
  };

  return (
    <Box>
      <div
        {...getRootProps({ className: "dropzone" })}
        className="border-2 border-dashed border-blue-500 bg-white p-14 mb-5 flex justify-center items-center cursor-pointer rounded-md"
      >
        <input {...getInputProps()} />
        <Text align="center" size="lg">
          Перетащите сюда файлы или нажмите для выбора
        </Text>
      </div>
      <div className="files flex flex-wrap gap-4">
        {data?.images?.length > 0 ? (
          <div className="flex flex-wrap gap-4 mb-4">
            {data?.images?.map((file) => {
              const fileLink = makeImageLink(camera.site, file.dest);
              const thumbFileLink = makeImageLink(
                camera.site,
                file.dest,
                "images"
              );
              return (
                <Box key={fileLink} className="flex flex-wrap relative">
                  <a href={fileLink} target="_blank" rel="noopener noreferrer">
                    <Image
                      src={thumbFileLink}
                      alt="Uploaded"
                      fit="cover"
                      className="!w-[100px] !h-[100px]"
                    />
                  </a>
                  <Button
                    style={{ position: "absolute", top: "0", right: "0" }}
                    onClick={() => submitDelete(file.dest, "image")}
                  >
                    Удалить
                  </Button>
                </Box>
              );
            })}
          </div>
        ) : (
          <Text align="center">Нет загруженных изображений</Text>
        )}
        {data?.files?.length > 0 ? (
          <div className="flex flex-wrap gap-4 mb-4">
            {data?.files?.map((file) => {
              const fileLink = makeImageLink(camera.site, file.dest);
              // const isPDF = file.dest.endsWith(".pdf");
              return (
                <Box key={fileLink} className="flex flex-wrap relative">
                  <a href={fileLink} target="_blank" rel="noopener noreferrer">
                    <Image
                      src="/pdf.png" // Иконка для PDF файлов
                      alt="PDF Document"
                      fit="cover"
                      className="!w-[100px] !h-[100px]"
                    />
                  </a>
                  <Button
                    style={{ position: "absolute", top: "0", right: "0" }}
                    onClick={() => submitDelete(file.dest, "file")}
                  >
                    Удалить
                  </Button>
                </Box>
              );
            })}
          </div>
        ) : (
          <div className="flex flex-wrap gap-4 mb-4">
            <Text align="center">Нет загруженных файлов</Text>
          </div>
        )}
      </div>

      {Object.keys(previews).map((fileName) => (
        <Box key={fileName} className="inline-flex relative flex-col gap-4">
          {fileName.endsWith(".pdf") ? (
            <Image
              src="/pdf.png" // Иконка для PDF файлов
              alt={fileName}
              className="!w-[150px] !h-[150px]"
              fit="cover"
            />
          ) : (
            <Image
              src={previews[fileName]}
              alt={fileName}
              className="!w-[150px] !h-[150px]"
              fit="cover"
            />
          )}
          <Text size="sm" align="center">
            {fileName}
          </Text>
        </Box>
      ))}
    </Box>
  );
};

export default ImageDropzone;
