import { useState } from "react";
import { Card, Text, Popover, Loader, Image, SimpleGrid } from "@mantine/core";

// libs
import { popoverData } from "../../../libs/api";
import { makeImageLink } from "../../../libs/util";

const PopoverFull = ({ data }) => {
  return (
    <Card withBorder shadow="sm" radius="md">
      <Card.Section withBorder inheritPadding py="xs">
        <Text fw={500}>{data.title}</Text>
      </Card.Section>

      <Text mt="sm" c="dimmed" size="sm">
        <Text fw={700} span inherit>
          TC Output:
        </Text>{" "}
        {data?.inner_data?.timecode_input}
        <br />
        <Text fw={700} span inherit>
          Audioinput:
        </Text>{" "}
        {data?.inner_data?.audio_input}
        <br />
        <Text fw={700} span inherit>
          Camera model:
        </Text>{" "}
        {data?.inner_data?.camera_model}
      </Text>

      {data?.inner_data?.images && data?.inner_data?.images.length > 0 && (
        <Card.Section mt="sm">
          <Image
            src={makeImageLink(data.site, data?.inner_data?.images[0].dest)}
          />
        </Card.Section>
      )}

      <Card.Section inheritPadding mt="sm" pb="md">
        <SimpleGrid cols={3}>
          {data?.inner_data?.images.map((image, i) => {
            const imageLink = makeImageLink(data.site, image.dest);
            return <Image src={imageLink} key={i} radius="sm" />;
          })}
        </SimpleGrid>
      </Card.Section>
    </Card>
  );
};

const PopoverImage = ({ link }) => {
  return <Image src={link} />;
};

const HoverPopover = ({ camera, token, type, children }) => {
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await popoverData(token, camera.id); // Запрос к серверу
      setData(result);
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleMouseEnter = () => {
    setOpened(true);
    if (type === "full") fetchData(); // Запрос на сервер при наведении
  };

  const handleMouseLeave = () => {
    setOpened(false);
  };

  return (
    <Popover
      width={type === "full" ? 400 : type === "image" ? 600 : 200}
      opened={opened}
      onClose={() => setOpened(false)}
      withArrow
      position="right"
      shadow="md"
    >
      <Popover.Target>
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {children}
        </div>
      </Popover.Target>
      <Popover.Dropdown className="title_popover">
        {loading ? (
          <Loader size="sm" />
        ) : data ? (
          type === "full" ? (
            <PopoverFull data={data} />
          ) : (
            type === "image" && <PopoverImage link={camera} />
          )
        ) : type === "image" ? (
          <PopoverImage link={camera} />
        ) : (
          <Text size="sm">Ошибка загрузки данных</Text>
        )}
      </Popover.Dropdown>
    </Popover>
  );
};

export default HoverPopover;
