import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createTheme, MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { HelmetProvider } from "react-helmet-async";
import { Notifications } from "@mantine/notifications";
//libs
import Hello from "./components/Hello";
import packg from "../package.json";

// styles
import "./index.css";
import { InitProvider } from "./components/context/InitContext";

const theme = createTheme({
  /** Put your mantine theme override here */
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <MantineProvider theme={theme}>
          <Notifications />
          <ModalsProvider>
            <InitProvider>
              <Hello
                author={packg.author}
                url={packg.repository.url}
                version={packg.version}
              />
              <App />
            </InitProvider>
          </ModalsProvider>
        </MantineProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
