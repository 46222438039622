import { createContext, useContext, useState } from "react";
import { useLocation } from "react-router";
import { useInitProvider } from "../../../context/InitContext";
import { useDebouncedValue, useDisclosure } from "@mantine/hooks";
import { getCameras, searchCameras } from "../../../../libs/api";
import { errorNotification } from "../../../../libs/util";

const CamerasContext = createContext();

export const CamerasProvider = ({ children }) => {
  const PAGE_SIZE = 50;
  const SEARCH_PAGE_SIZE = 50;
  const location = useLocation();
  const { token } = useInitProvider();
  const [openedAdd, { open: openAdd, close: closeAdd }] = useDisclosure(false);

  const [camerasData, setCamerasData] = useState([]);
  const [loadingCameras, setLoadingCameras] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    parseInt(new URLSearchParams(location.search).get("page")) || 1
  );
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [searchValue, setSearchValue] = useState("");
  const [debounced] = useDebouncedValue(searchValue, 500);

  // Получаем камеры
  const fetchCameras = async () => {
    setLoadingCameras(true);
    try {
      setCamerasData([]);
      const { data, total_pages, total_items } = await getCameras(
        token,
        currentPage,
        PAGE_SIZE
      );
      setCamerasData(data);
      setTotalPages(total_pages);
      setTotalItems(total_items);
    } catch (e) {
      console.error("Failed to fetch cameras");
      errorNotification("Ошибка", "Произошла ошибка при получении камер!");
    }
    setLoadingCameras(false);
  };

  // Поиск по камерам
  const fetchSearch = async () => {
    setLoadingCameras(true);
    try {
      const { data, total_pages, total_items } = await searchCameras(
        token,
        debounced,
        currentPage,
        SEARCH_PAGE_SIZE
      );
      setCamerasData(data);
      setTotalPages(total_pages);
      setTotalItems(total_items);
    } catch (error) {
      errorNotification("Ошибка", "Произошла ошибка при поиске!");
      console.error("Failed search");
    }
    setLoadingCameras(false);
  };

  return (
    <CamerasContext.Provider
      value={{
        fetchSearch,
        fetchCameras,
        openedAdd,
        openAdd,
        closeAdd,
        camerasData,
        setCamerasData,
        loadingCameras,
        setLoadingCameras,
        currentPage,
        setCurrentPage,
        totalPages,
        totalItems,
        setTotalPages,
        searchValue,
        setSearchValue,
        debounced,
      }}
    >
      {children}
    </CamerasContext.Provider>
  );
};

export const useCamerasProvider = () => {
  return useContext(CamerasContext);
};
