import React, { useEffect, useState } from "react";
import { Text, Grid, Pill } from "@mantine/core";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useNavigate, useParams } from "react-router";

// libs
import { PanelStatsHeadTable, PanelStatsTr } from "./PanelStatsTables";
import { LoadingPanel } from "../Layout/Loading";
import { getStatistics, getRequestsStatistics } from "../../../libs/api";
import { errorNotification } from "../../../libs/util";
import { useInitProvider } from "../../context/InitContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const PanelStats = () => {
  const { token } = useInitProvider();
  const defaultChartData = {
    labels: [],
    datasets: [
      {
        label: "Количество запросов",
        data: [],
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };
  const navigate = useNavigate();
  const { tabValue } = useParams();
  const [loadingStats, setLoadingStats] = useState([]);
  const [requestsStatsData, setRequestsStatsData] = useState([]);
  const [loadingRequestsStats, setLoadingRequestsStats] = useState(false);
  const [chartData7, setChartData7] = useState(defaultChartData);
  const [chartData30, setChartData30] = useState(defaultChartData);
  const [chartData365, setChartData365] = useState(defaultChartData);

  const LIMIT_REQUESTS = 20;

  /**
   * Получим статистику по дням (7, 30 и 365 дней)
   */
  const fetchStatistic = async () => {
    try {
      setLoadingStats(true);
      const statistics = await getStatistics(token);
      // Обновляем данные для каждого периода
      setChartData7({
        labels: statistics["7"].labels,
        datasets: [
          {
            ...chartData7.datasets[0],
            data: statistics["7"].data,
          },
        ],
      });
      setChartData30({
        labels: statistics["30"].labels,
        datasets: [
          {
            ...chartData30.datasets[0],
            data: statistics["30"].data,
          },
        ],
      });
      setChartData365({
        labels: statistics["365"].labels,
        datasets: [
          {
            ...chartData365.datasets[0],
            data: statistics["365"].data,
          },
        ],
      });
    } catch (e) {
      errorNotification("Ошибка", "Произошла ошибка при получении статистики");
      console.error("Failed to fetch stats", e);
    }
    setLoadingStats(false);
  };

  /**
   * Получим статистику по запросам (последние LIMIT_REQUESTS запросов)
   */
  const fetchRequestsStatistic = async () => {
    setLoadingRequestsStats(true);
    try {
      const { data } = await getRequestsStatistics(token, LIMIT_REQUESTS);
      setRequestsStatsData(data);
    } catch (e) {
      errorNotification("Ошибка", "Произошла ошибка при получении запросов");
      console.error("Failed to fetch requests stats", e);
    }
    setLoadingRequestsStats(false);
  };

  const clear = () => {
    setRequestsStatsData([]);
    setChartData7(defaultChartData);
    setChartData30(defaultChartData);
    setChartData365(defaultChartData);
  };

  // Не хотелось делать роуты, поэтому будем обрабатывать прям тут
  useEffect(() => {
    clear();
    if (tabValue === undefined) {
      navigate("/panel/cameras");
      return;
    }
    if (tabValue === "statistics") {
      fetchStatistic();
      fetchRequestsStatistic();
    }
  }, [tabValue]);

  return (
    <div className="statistics p-2">
      {loadingStats ? (
        <LoadingPanel>Загружаем статистику...</LoadingPanel>
      ) : (
        <Grid>
          <Grid.Col span={6}>
            <Text size="md" mt="md">
              Статистика запросов бота за последние <Pill>7 дней</Pill>:
            </Text>
            <Line data={chartData7} />
          </Grid.Col>

          <Grid.Col span={6}>
            <Text size="md" mt="md">
              Статистика запросов бота за последние <Pill>30 дней</Pill>:
            </Text>
            <Line data={chartData30} />
          </Grid.Col>

          <Grid.Col span={6}>
            <Text size="md" mt="md">
              Статистика запросов бота за последние <Pill>365 дней</Pill>:
            </Text>
            <Line data={chartData365} />
          </Grid.Col>
        </Grid>
      )}

      {loadingRequestsStats ? (
        <LoadingPanel>Загружаем запросы...</LoadingPanel>
      ) : requestsStatsData ? (
        <>
          <Text size="md" mt="md" mb="md">
            Последние <Pill>{LIMIT_REQUESTS} запросов</Pill>:
          </Text>
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-2">
            <PanelStatsHeadTable />
            <tbody>
              {requestsStatsData.map((data, i) => (
                <PanelStatsTr key={i} data={data} />
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <LoadingPanel>Нет данных!</LoadingPanel>
      )}
    </div>
  );
};

export default PanelStats;
