// src/App.js
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// context
import { useInitProvider } from "./components/context/InitContext";

// components
import Panel from "./components/Panel";
import LoginForm from "./components/Panel/User/LoginForm";
import NotFound from "./NotFound";

// styles
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const { token } = useInitProvider();
  return token ? <Component {...rest} /> : <Navigate to="/login" />;
};

const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginForm />} />
      {/* Используем компонент ProtectedRoute для защиты маршрутов */}
      <Route path="/panel" element={<ProtectedRoute element={Panel} />} />
      <Route
        path="/panel/:tabValue"
        element={<ProtectedRoute element={Panel} />}
      />
      {/* Используем ProtectedRoute для корневого маршрута */}
      <Route path="/" element={<ProtectedRoute element={Panel} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
