import React, { useEffect } from "react";
import { Button, Text, Group, Tabs, rem, TextInput, Pill } from "@mantine/core";
import {
  IconX,
  IconCamera,
  IconChartPie3,
  IconLoader,
  IconReload,
} from "@tabler/icons-react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useCamerasProvider } from "./Cameras/context/CamerasProvider";

const PanelHeader = ({ tabHeight }) => {
  const {
    openAdd,
    fetchSearch,
    fetchCameras,
    searchValue,
    setSearchValue,
    debounced,
    loadingCameras,
    totalItems,
  } = useCamerasProvider();
  const { tabValue } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const tabsIconStyle = { width: rem(15), height: rem(15) };
  const iconStyle = { width: rem(16), height: rem(16), cursor: "pointer" };
  const refetch = () => {
    setSearchValue("");
    fetchCameras();
  };
  const icon = loadingCameras ? (
    <IconLoader style={iconStyle} />
  ) : searchValue ? (
    <IconX onClick={() => refetch()} style={iconStyle} />
  ) : (
    <IconReload onClick={() => refetch()} style={iconStyle} />
  );

  useEffect(() => {
    // Вернемся на первую страницу (т.к. если страница например поиска открыта на 2, то при очистке поиска откроется 2я страница из fetchCameras данных, которых может и не быть)
    if (debounced) {
      navigate(`${location.pathname}?page=1`, { replace: true });
      fetchSearch();
    }
  }, [debounced]);

  return (
    <Group position="apart" className="w-full justify-between">
      <div>
        <Tabs.List style={{ height: tabHeight }}>
          <Tabs.Tab
            value="cameras"
            leftSection={<IconCamera style={tabsIconStyle} />}
            p="md"
          >
            <Text size="md">Камеры</Text>
          </Tabs.Tab>
          <Tabs.Tab
            value="statistics"
            leftSection={<IconChartPie3 style={tabsIconStyle} />}
            p="md"
          >
            <Text size="md">Статистика</Text>
          </Tabs.Tab>
        </Tabs.List>
      </div>

      {tabValue === "cameras" && (
        <div className="flex items-center gap-5">
          {searchValue && !loadingCameras && totalItems ? (
            <div>
              Найдено <Pill>{totalItems}</Pill> результатов
            </div>
          ) : (
            <></>
          )}
          <TextInput
            placeholder="Поиск камер"
            value={searchValue}
            onChange={(event) => setSearchValue(event.currentTarget.value)}
            rightSection={icon}
          />

          <Button className="right-2" onClick={openAdd}>
            Добавить
          </Button>
        </div>
      )}
    </Group>
  );
};

export default PanelHeader;
