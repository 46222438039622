import React from "react";

const Main = ({ children, className }) => {
  return (
    <div className={["dark:bg-gray-900", "antialiased", className].join(" ")}>
      {children}
    </div>
  );
};

export default Main;
