import React from "react";
import { Tabs } from "@mantine/core";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router";

// libs
import UserProfile from "./User/UserProfile";
import Main from "./Layout/Main";
import Footer from "./Layout/Footer";

// panel
import PanelStickyHeader from "./PanelStickyHeader";
import PanelCameras from "./Cameras/PanelCameras";
import PanelStats from "./Stats/PanelStats";
import PanelHeader from "./PanelHeader";
import { CamerasProvider } from "./Cameras/context/CamerasProvider";

const Panel = () => {
  const navigate = useNavigate();
  const { tabValue } = useParams();
  const panelTitlePostfix = "TC GURU";
  const defaultPanelTitle = `Панель управления ботом ${panelTitlePostfix}`;
  const tabHeight = "60px";

  return (
    <div className="panel py-3">
      <Helmet>
        <title>
          {tabValue === "cameras"
            ? `Камеры - ${panelTitlePostfix}`
            : tabValue === "statistics"
            ? `Статистика - ${panelTitlePostfix}`
            : defaultPanelTitle}
        </title>
      </Helmet>
      <UserProfile />
      <Main>
        <div className="mx-auto max-w-screen-2xl">
          <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden min-h-dvh">
            <CamerasProvider>
              <Tabs
                value={tabValue}
                onChange={(value) => navigate(`/panel/${value}`)}
              >
                <div style={{ height: tabHeight }}>
                  <PanelStickyHeader height={tabHeight}>
                    <PanelHeader tabHeight={tabHeight} />
                  </PanelStickyHeader>
                </div>

                <Tabs.Panel value="cameras">
                  <PanelCameras />
                </Tabs.Panel>

                <Tabs.Panel value="statistics">
                  <PanelStats />
                </Tabs.Panel>
              </Tabs>
            </CamerasProvider>
          </div>
        </div>
      </Main>
      <Footer />
    </div>
  );
};
export default Panel;
