import React from "react";
import { Container, Title, Text, Button, Center } from "@mantine/core";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Container size="xs" style={{ height: "100vh" }}>
      <Center style={{ height: "100%" }}>
        <div>
          <Title align="center" order={1} mb="md">
            404
          </Title>
          <Text align="center" size="lg" mb="lg">
            Ой! Страница не найдена.
          </Text>
          <Button component={Link} to="/" variant="outline" fullWidth>
            Вернуться на главную
          </Button>
        </div>
      </Center>
    </Container>
  );
};

export default NotFound;
