import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { modals } from "@mantine/modals";
import { Modal, Text, ScrollArea, Pagination } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

// Компоненты
import PanelCamerasForm from "./PanelCamerasForm";
import { PanelCamerasHeadTable, PanelCamerasTr } from "./PanelCamerasTables";
import { LoadingPanel } from "../Layout/Loading";
import { deleteCamera } from "../../../libs/api";
import { errorNotification, successNotification } from "../../../libs/util";
import { useInitProvider } from "../../context/InitContext";
import { useCamerasProvider } from "./context/CamerasProvider";

const PanelCameras = () => {
  const {
    closeAdd,
    openedAdd,
    camerasData,
    loadingCameras,
    setCamerasData,
    currentPage,
    setCurrentPage,
    totalPages,
    fetchCameras,
    fetchSearch,
    searchValue,
  } = useCamerasProvider();
  const { token } = useInitProvider();
  const navigate = useNavigate();
  const location = useLocation();

  // Состояние
  const [camera, setCamera] = useState({});
  const [openedEdit, { open: openEdit, close: closeEdit }] =
    useDisclosure(false);
  const overlayProps = {
    backgroundOpacity: 0.55,
    blur: 3,
  };

  // Очищаем данные камер
  const clear = () => {
    setCamerasData([]);
  };

  useEffect(() => {
    Object.keys(camera).length > 0 && openEdit();
  }, [camera]);

  useEffect(() => {
    !openedEdit && setCamera({});
  }, [openedEdit]);

  // Открытие модального окна для удаления
  const openDeleteModal = (id, title) =>
    modals.openConfirmModal({
      title: "Удаление публикации",
      centered: true,
      children: (
        <Text size="sm">
          Уверены, что хотите удалить "{title}"? Действие не возвратно
        </Text>
      ),
      labels: { confirm: "Удалить данные", cancel: "Отмена" },
      confirmProps: { color: "red" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => fetchDelete(id),
    });

  // Удаляем камеру
  const fetchDelete = async (id) => {
    try {
      const deleteCameraRes = await deleteCamera(token, id);
      if (!deleteCameraRes || deleteCameraRes?.detail) {
        errorNotification("Ошибка", "Произошла ошибка при удалении камеры!");
        return;
      }
      await fetchCameras();
      successNotification("Успешно", "Камера удалена!");
    } catch (e) {
      console.error("Failed to delete camera");
      errorNotification("Ошибка", "Произошла ошибка при удалении камеры!");
    }
  };

  // Обновляем данные камеры
  const submitEdit = (cameraData) => setCamera(cameraData);

  // Открываем модальное окно для удаления камеры
  const submitDelete = (id, title) => openDeleteModal(id, title);

  // Получаем текущую страницу из URL и обновляем состояние
  useEffect(() => {
    const page =
      parseInt(new URLSearchParams(location.search).get("page")) || 1;
    setCurrentPage(page);
  }, [location.search]);

  // Обновляем данные при изменении маршрута или страницы
  useEffect(() => {
    clear();
    if (!searchValue) {
      fetchCameras();
    } else {
      fetchSearch();
    }
  }, [currentPage]);

  // Обработка изменения страницы
  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`${location.pathname}?page=${page}`, { replace: true });
  };

  const PaginationZ = () =>
    totalPages > 1 ? (
      <div className="p-2">
        <Pagination
          defaultValue={currentPage}
          page={currentPage}
          onChange={handlePageChange}
          total={totalPages}
          position="center"
          withEdges
          size="sm"
        />
      </div>
    ) : (
      <></>
    );

  return (
    <div className="cameras">
      <Modal
        scrollAreaComponent={ScrollArea.Autosize}
        opened={openedAdd}
        size="xl"
        onClose={closeAdd}
        overlayProps={overlayProps}
      >
        <Helmet>
          <title>Добавление новой камеры</title>
        </Helmet>
        <PanelCamerasForm
          token={token}
          camera={{}}
          closeAction={closeAdd}
          fetchData={fetchCameras}
          type="add"
        />
      </Modal>
      <Modal
        scrollAreaComponent={ScrollArea.Autosize}
        opened={openedEdit}
        size="xl"
        onClose={closeEdit}
        overlayProps={overlayProps}
      >
        <Helmet>
          <title>Редактирование {camera?.title || ""}</title>
        </Helmet>
        <PanelCamerasForm
          token={token}
          camera={camera}
          closeAction={closeEdit}
          fetchData={fetchCameras}
          type="edit"
        />
      </Modal>
      <div className="overflow-x-auto">
        {loadingCameras ? (
          <LoadingPanel>Загружаем камеры...</LoadingPanel>
        ) : camerasData.length ? (
          <>
            <PaginationZ />
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <PanelCamerasHeadTable />
              <tbody>
                {camerasData
                  .sort((a, b) => b.id - a.id)
                  .map((camera, index) => (
                    <PanelCamerasTr
                      key={index}
                      token={token}
                      camera={camera}
                      submitDelete={submitDelete}
                      submitEdit={submitEdit}
                    />
                  ))}
              </tbody>
            </table>
            <PaginationZ />
          </>
        ) : (
          <LoadingPanel>Нет данных!</LoadingPanel>
        )}
      </div>
    </div>
  );
};

export default PanelCameras;
