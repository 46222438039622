export const API_HOST = process.env.REACT_APP_API_URL;
export const API_URL = `${process.env.REACT_APP_API_URL}/api`;
export const TELERGAM_URL = process.env.REACT_APP_TELEGRAM_BOT_URL;

// ------------------------------ USERS

export async function login(username, password, captchaToken) {
  const response = await fetch(`${API_URL}/users/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      username,
      password,
      hcaptcha_token: captchaToken,
    }),
  });
  if (!response.ok) {
    throw new Error("Failed to log in");
  }
  return response.json();
}

export async function register(username, password) {
  const response = await fetch(`${API_URL}/users/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password }),
  });
  if (!response.ok) {
    throw new Error("Failed to register");
  }
  return response.json();
}

export async function getUser(username) {
  const response = await fetch(`${API_URL}/users/${username}`);
  if (!response.ok) {
    throw new Error("Failed to fetch user");
  }
  return response.json();
}

export async function updateUser(username, newUsername, newPassword) {
  const response = await fetch(`${API_URL}/users/${username}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username: newUsername, password: newPassword }),
  });
  if (!response.ok) {
    throw new Error("Failed to update user");
  }
  return response.json();
}

export async function deleteUser(username) {
  const response = await fetch(`${API_URL}/users/${username}`, {
    method: "DELETE",
  });
  if (!response.ok) {
    throw new Error("Failed to delete user");
  }
  return response.json();
}

// Новая функция для проверки токена
export async function verifyToken(token) {
  const response = await fetch(`${API_URL}/users/verify-token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Token verification failed");
  }

  return response.json();
}

// ------------------------------ FILES

export async function uploadFiles(cameraId, site, token, fromSiteId, formData) {
  const response = await fetch(
    `${API_URL}/files/upload_files?camera_id=${cameraId}&site=${site}&from_site_id=${fromSiteId}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    }
  );

  if (!response.ok) {
    throw new Error("Failed to upload files");
  }

  return response.json();
}

export async function getFiles(cameraId, token) {
  if (!cameraId) return;
  const response = await fetch(`${API_URL}/files/${cameraId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch files");
  }

  return response.json();
}

export async function deleteFile(cameraId, token, filePath, fileType, site) {
  const response = await fetch(`${API_URL}/files/delete_file`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      item_path: filePath,
      item_type: fileType,
      camera_id: cameraId,
      site: site,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to delete file");
  }

  return response.json();
}

// ------------------------------ CAMERAS

export async function getCamerasTags(token) {
  const response = await fetch(`${API_URL}/cameras/tags`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error("Failed to fetch tags");
  }
  return response.json();
}

export async function getCameras(token, page = 1, page_size = 10) {
  const response = await fetch(
    `${API_URL}/cameras?page=${page}&page_size=${page_size}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to fetch cameras");
  }
  return response.json();
}

export const createCamera = async (token, cameraData) => {
  const response = await fetch(`${API_URL}/cameras/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(cameraData),
  });
  return await response.json();
};

export const updateCamera = async (token, cameraId, cameraData) => {
  const response = await fetch(`${API_URL}/cameras/update/${cameraId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(cameraData),
  });
  return await response.json();
};

export const deleteCamera = async (token, cameraId) => {
  const response = await fetch(`${API_URL}/cameras/delete/${cameraId}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return await response.json();
};

// ------------------------------ OTHER

export const popoverData = async (token, cameraId) => {
  const response = await fetch(`${API_URL}/popover/${cameraId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return await response.json();
};

// ------------------------------ STATISTICS

export const getStatistics = async (token) => {
  const response = await fetch(`${API_URL}/statistics`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return await response.json();
};

export const getRequestsStatistics = async (token, limit) => {
  const response = await fetch(
    `${API_URL}/statistics/requests?limit=${limit}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return await response.json();
};

// ------------------------------ SEARCH

export async function searchCameras(
  token,
  query,
  current_page,
  items_per_page
) {
  const response = await fetch(
    `${API_URL}/search/cameras?query=${query}&current_page=${current_page}&items_per_page=${items_per_page}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    throw new Error("Failed to search cameras");
  }
  return response.json();
}
