import React, { useState, useEffect } from "react";
import { Paper } from "@mantine/core";

const PanelStickyHeader = ({ children, height }) => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Paper
      style={{
        height,
        position: isSticky ? "fixed" : "static",
        top: isSticky ? "0" : "auto",
        left: isSticky ? "0" : "auto",
        right: isSticky ? "0" : "auto",
        zIndex: 100,
        background: "none",
      }}
    >
      <div
        className={[
          "relative mx-auto max-w-screen-2xl bg-white",
          isSticky ? "shadow-[rgba(0,0,15,0.2)_0px_2px_4px_0px]" : "",
        ].join(" ")}
      >
        {children}
        {!isSticky && (
          <div className="absolute left-0 right-0 inset-x-0 bottom-0 h-px bg-gray-300" />
        )}
      </div>
    </Paper>
  );
};

export default PanelStickyHeader;
