import React, { useEffect, useState } from "react";
import { Avatar, Button, Fieldset, Pill, Text } from "@mantine/core";

// libs
import { getUser } from "../../../libs/api";
import { useInitProvider } from "../../context/InitContext";
import Main from "../Layout/Main";
import { LoadingPanel } from "../Layout/Loading";

const UserProfile = () => {
  const { username, handleLogout } = useInitProvider();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [localUserData, setLocalUserData] = useState({});

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      try {
        const userData = await getUser(username);
        setLocalUserData(userData);
      } catch (e) {
        console.error("Failed to fetch user");
        setError(true);
      }
      setLoading(false);
    };
    fetchUser();
  }, [username]);

  return (
    <Main className="mb-5">
      <div className="mx-auto max-w-screen-2xl">
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="overflow-x-auto p-2">
            <Fieldset legend="Профиль" variant="filled" p="sm">
              <div className="flex items-center justify-between min-h-10">
                {loading ? (
                  <LoadingPanel no_p>...</LoadingPanel>
                ) : error ? (
                  <>Ошибка при загрузке пользователя</>
                ) : localUserData ? (
                  <>
                    <div className="flex items-center space-x-4">
                      <Avatar src={localUserData?.gravatar} />
                      <Text className="text-md">
                        Привет, {localUserData.username}!
                        {localUserData.email && (
                          <Pill ml={2}>{localUserData.email}</Pill>
                        )}
                      </Text>
                    </div>
                    <Button onClick={handleLogout}>Выйти</Button>
                  </>
                ) : (
                  <div>Пользователь не найден!</div>
                )}
              </div>
            </Fieldset>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default UserProfile;
